import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { CdeSidenavComponent } from './cde-sidenav/cde-sidenav.component';
import { TranslateModule } from '@ngx-translate/core';
import { CdeSidenavItemComponent } from './cde-sidenav/cde-sidenav-item/cde-sidenav-item.component';
import { HelperModule } from '@app/helper/helper.module';
import { DirectiveModule } from '@app/shared/directive/directive.module';

@NgModule({
    imports: [
        CommonModule, RouterModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        TranslateModule,
        HelperModule,
        DirectiveModule,
        CdeSidenavComponent,
        CdeSidenavItemComponent,
    ],
    exports: [CdeSidenavComponent]
})
export class CdeSidenavModule { }
