import { ICampaignJsonDetailReport, ICampaignReport, ICampaignReportRequest, IDailyReportJsonExport, IReportPatch } from './../models/analytics.model';
import { Injectable } from '@angular/core';
import { catchError, map, take } from 'rxjs/operators';
import {
  DailyMachineReport,
  MachineDetailedValues,
  MachinesOperatingContent,
  MachinesOperatingValue,
  PaginatedDailyMachineReport,
  PaginatedMachines,
  IRawTableDataResponse,
} from '../models/analytics.model';
import { RequestParam, ServiceCall } from './service-call.service';
import { Observable, throwError } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Paginated } from '../models/page.model';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';
import { environment } from '@environments/environment';
import { ServiceCallResponse } from '../models/service-call.model';
import { downloadBlob } from '../utils/download-blob';
import moment from 'moment';

export const MACHINE_ANALYSIS_URL = 'v3/machine-analysis';
export const MACHINES_URL = 'v2/machines';
export const MACHINE_RAW_DATA_URL = 'v3/machine-reports/raw-data-exports';
export const DAILY_REPORTS_URL = 'v1/machine-reports/daily-reports';
export const CAMPAIGN_REPORTS_URL = 'v1/machine-reports/campaign-reports';

@Injectable()
export class AnalyticsService {
  private machineAnalysisUrl = `${environment.apiBaseUrl}/${MACHINE_ANALYSIS_URL}`;
  private machinesUrl = `${environment.apiBaseUrl}/${MACHINES_URL}`;
  private machineRawDataUrl = `${environment.apiBaseUrl}/${MACHINE_RAW_DATA_URL}`;
  private dailyReportsUrl = `${environment.apiBaseUrl}/${DAILY_REPORTS_URL}`;
  private campaignReportsUrl = `${environment.apiBaseUrl}/${CAMPAIGN_REPORTS_URL}`;

  private alertOptions = {
    ...TOASTR_ALERT_OPTIONS,
    timeOut: 3000,
  };

  constructor(private translate: TranslateService, private serviceCall: ServiceCall, private alertService: AlertService) {}

  getMachinesMeasurementValues(machineIds: string[], timeZone: string) {
    return this.serviceCall
      .get(`${this.machineAnalysisUrl}/measurement-values?machineIds=${machineIds}&timeZone=${timeZone}`)
      .pipe(map((response) => response.data.values));
  }

  getMachinesOperatingValues(machineIds: string[], timeZone: string, widgetid?: string) {
    return this.serviceCall.get(`${this.machineAnalysisUrl}/operating-values?machineIds=${machineIds}&timeZone=${timeZone}`).pipe(
      map((response) => {
        const opValues = new Array();
        response.data.values.forEach((value: { machineId: string; duration: number; content: MachinesOperatingContent[] }) => {
          opValues.push({
            machineId: value.machineId,
            duration: value.duration,
            content: value.content,
            widgetid: widgetid,
          });
        });

        return opValues as MachinesOperatingValue[];
      })
    );
  }

  getMachineDetailedValues(
    machineId: string,
    timeZone: string,
    measuredFromLocalDate: string,
    measuredUntilLocalDate: string
  ): Observable<MachineDetailedValues> {
    return this.serviceCall
      .get(
        // eslint-disable-next-line max-len
        `${this.machineAnalysisUrl}/detailed-values?machineId=${machineId}&timeZone=${timeZone}&measuredFromLocalDate=${measuredFromLocalDate}&measuredUntilLocalDate=${measuredUntilLocalDate}`
      )
      .pipe(map((response) => response.data));
  }

  getMachineRawDataValues(page: number, size: number, machineId?: string, sort?: string[]): Observable<Paginated<IRawTableDataResponse>> {
    let params: RequestParam = new HttpParams();

    params = machineId ? params.set('machineId', machineId) : params;
    params = params.set('page', page).set('size', size);
    params = sort ? params.set('sort', sort.join()) : params;

    return this.serviceCall.get(this.machineRawDataUrl, params).pipe(
      map((response) => response.data),
      catchError((error) => {
        this.alertService.showError(
          this.translate.instant('error_messages.could_not_add_data'),
          this.translate.instant('alert_message.try_again'),
          'alert-error-icon',
          this.alertOptions
        );
        return throwError(error);
      })
    );
  }

  getMachineRawDataReport(rawDataReportId: string) {
    return this.serviceCall.get(`${this.machineRawDataUrl}/${rawDataReportId}`).pipe(
      map(
        (response: ServiceCallResponse) => response.data as IRawTableDataResponse
      ),
      catchError((error) => {
        this.alertService.showError(
          this.translate.instant('no_data.data_not_available'),
          this.translate.instant('alert_message.try_again'),
          'alert-error-icon',
          this.alertOptions
        );
        return throwError(() => new Error(error.data.message));
      })
    );
  }

  deleteMachineRawDataItem(rawDataExportId: string) {
    return this.serviceCall.delete(`${this.machineRawDataUrl}/${rawDataExportId}`).pipe(
      map(
        (response: { data: any; failCode: number; meta: any; statusCode: number; success: boolean }) => response.statusCode,
        catchError((error) => {
          this.alertService.showError(
            this.translate.instant('analytics.raw_data_modal.could_not_delete_exported_data'),
            this.translate.instant('alert_message.try_again'),
            'alert-error-icon',
            this.alertOptions
          );
          return throwError(error);
        })
      )
    );
  }

  downloadMachineRawDataItem(rawDataExportId: string) {
    return this.serviceCall.getZip(`${this.machineRawDataUrl}/${rawDataExportId}/zip`).pipe(
      map(
        (response) => response.data,
        catchError((error: any) => {
          this.alertService.showError(
            this.translate.instant('analytics.raw_data_modal.could_not_delete_exported_data'), // add message for download error
            this.translate.instant('alert_message.try_again'),
            'alert-error-icon',
            this.alertOptions
          );
          return throwError(error);
        })
      )
    );
  }

  getPaginatedMachines(
    page: number,
    size: number,
    characteristic: string,
    machineIds: string,
    archived: boolean
  ): Observable<PaginatedMachines> {
    const url = machineIds
      ? `${this.machinesUrl}?page=${page}&size=${size}&characteristic=${characteristic}&machineIds=${machineIds}&archived=${archived}`
      : `${this.machinesUrl}?page=${page}&size=${size}&characteristic=${characteristic}&archived=${archived}`;

    return this.serviceCall.get(url).pipe(map((response) => response.data));
  }

  getDailyReport(page: number, size: number, machineId?: string, sort?: string[]): Observable<PaginatedDailyMachineReport> {
    let params: RequestParam = new HttpParams();

    params = machineId ? params.set('machineId', machineId) : params;
    params = params.set('page', page).set('size', size);
    params = sort ? params.set('sort', sort.join()) : params;

    return this.serviceCall.get(this.dailyReportsUrl, params).pipe(map((response) => response.data));
  }

  getSingleDailyReport(reportId: string) {
    return this.serviceCall.get(`${this.dailyReportsUrl}/${reportId}`).pipe(map((response) => response.data as DailyMachineReport));
  }

  deleteDailyReport(reportId: string) {
    const url = `${this.dailyReportsUrl}/${reportId}`;
    return this.serviceCall.delete(url).pipe(map((response) => response.statusCode));
  }

  patchDailyReport(reportId: string, active: boolean): Observable<DailyMachineReport> {
    const reportPatch: IReportPatch = {
      active: active,
    };
    const url = `${this.dailyReportsUrl}/${reportId}`;
    return this.serviceCall.patch(url, reportPatch).pipe(map((response) => response.data));
  }

  postDailyReport(dailyReport: DailyMachineReport): Observable<DailyMachineReport> {
    return this.serviceCall.post(`${this.dailyReportsUrl}`, dailyReport).pipe(
      map((response) => {
        if (response.statusCode === 201) {
          return response.data;
        }
      })
    );
  }

  getCampaignReports(
    page: number,
    size: number,
    options?: { machineId?: string; property: string; order: string }
  ): Observable<Paginated<ICampaignReport>> {
    const params: { [key: string]: string | number } = {
      page,
      size,
    };

    if (options?.machineId) {
      params['machineId'] = options.machineId;
    }

    if (options?.property && options?.order) {
      params['sort'] = `${options.property},${options.order}`;
    }

    return this.serviceCall.get(this.campaignReportsUrl, params).pipe(
      map((response) => response.data),
      catchError((error: ServiceCallResponse) => {
        this.alertService.showError(
          this.translate.instant('error_messages.could_not_add_data'),
          this.translate.instant('alert_message.try_again'),
          'alert-error-icon',
          this.alertOptions
        );
        return throwError(() => new Error(error.data.message));
      })
    );
  }

  postCampaignReport(campaignReportRequest: ICampaignReportRequest) {
    return this.serviceCall.post(`${this.campaignReportsUrl}`, campaignReportRequest).pipe(
      map((response) => response.data as ICampaignReport),
      catchError((error: ServiceCallResponse) => {
        this.alertService.showError(
          this.translate.instant('no_data.try_again_later'),
          this.translate.instant('alert_message.try_again'),
          'alert-error-icon',
          this.alertOptions
        );
        return throwError(() => new Error(error.data.message));
      })
    );
  }

  deleteCampaignReport(campaignReportId: string) {
    return this.serviceCall.delete(`${this.campaignReportsUrl}/${campaignReportId}`).pipe(
      map((response: ServiceCallResponse) => response),
      catchError((error) => {
        this.alertService.showError(
          this.translate.instant('analytics.raw_data_modal.could_not_delete_exported_data'),
          this.translate.instant('alert_message.try_again'),
          'alert-error-icon',
          this.alertOptions
        );
        return throwError(() => new Error(error.data.message));
      })
    );
  }

  getCampaignReportJsonExport(campaignReportId: string) {
    const url = `${this.campaignReportsUrl}/${campaignReportId}/export-types/json`;
    return this.serviceCall.get(url).pipe(map((response) => response.data as ICampaignJsonDetailReport[]));
  }

  getCampaignReport(campaignReportId: string) {
    const url = `${this.campaignReportsUrl}/${campaignReportId}`;
    return this.serviceCall.get(url).pipe(map((response) => response.data as ICampaignReport));
  }

  getCampaignReportXlsxExport(campaignReportId: string, campaignReportName?: string) {
    const url = `${this.campaignReportsUrl}/${campaignReportId}/export-types/xlsx`;
    return this.getReportXlsxExport(url, campaignReportName);
  }

  getDailyReportJsonExport(dailyReportId: string, reportDay?: string) {
    const url = `${this.dailyReportsUrl}/${dailyReportId}/export-types/json`;
    if (reportDay != null) {
      const urlWithDay = `${url}?reportDay=${reportDay}`;
      return this.serviceCall.get(urlWithDay).pipe(map((response) => response.data as IDailyReportJsonExport[]));
    }

    return this.serviceCall.get(url).pipe(map((response) => response.data as IDailyReportJsonExport[]));
  }

  getDailyReportXlsxExport(dailyReportId: string, reportName?: string) {
    const url = `${this.dailyReportsUrl}/${dailyReportId}/export-types/xlsx`;
    return this.getReportXlsxExport(url, reportName);
  }

  private getReportXlsxExport(url: string, name?: string) {
    return this.serviceCall
      .getOpenXMLFormatsXlsx(url)
      .pipe(
        take(1),
        map((response) => {
          const xlsxData = response.data as Blob | null;
          if (xlsxData == null) {
            return;
          }

          const filename = `${name ?? 'report'}-${moment().format('YYYY-MM-DD_HH-mm-ss').toString()}.xlsx`;
          downloadBlob(xlsxData, filename);
        }),
        catchError((error: any) => {
          this.alertService.showError(
            this.translate.instant('analytics.reports.campaign-report.export.xlsx-error'),
            this.translate.instant('alert_message.try_again'),
            'alert-error-icon',
            this.alertOptions
          );
          return throwError(() => new Error(error.data.message));
        })
      )
      .subscribe(() => {});
  }
}
