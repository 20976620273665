<a
  class="custom-list-item"
  [routerLink]="disableRouterLink ? undefined : hasRoute ? [item.route] : ['/to-route']"
  [routerLinkActive]="'active'"
  [routerLinkActiveOptions]="{ exact: false }"
  [ngClass]="{ 'no-route': !hasRoute, disable: disableRouterLink}"
  (click)="toggleOnClick(item)"
  ariaCurrentWhenActive="page"
  >
  @if (item && item.svgIcon) {
    <lib-icon
      class="grid nav-lib-icon"
      [iconId]="item.svgIcon.iconId ?? ''"
      [iconName]="item.svgIcon.iconName"
      [namespace]="item.svgIcon.namespace ?? 'cc'"
      [iconStyle]="item.svgIcon.style ?? 'bold'"
      [size]="item.svgIcon.size ?? 20"
      >
    </lib-icon>
  }

  @if (isExpanded) {
    <h4 class="custom-list-item-text">
      @if (item && item.translate) {
        {{ item.text | translate }}
      }
      @if (item && !item.translate) {
        {{ item.text }}
      }
    </h4>
  }
</a>
