<ng-container>
  <mat-drawer-container
    class="example-container mat-typography"
    [autosize]="mobileQuery.matches ? false : true"
    id="drawerContent"
    [class.mobile-layout]="mobileQuery.matches"
  >
    <mat-drawer #drawer mode="side" disableClose="true" opened="true" class="left-sidenav" [ngClass]="{ 'is-expanded': isExpanded() }">
      <ul
        class="nav-list"
        [ngClass]="{ 'is-expanded': isExpanded() }"
        [attr.data-testid]="isExpanded() ? 'sidenav-item-list-expanded' : 'sidenav-item-list-not-expanded'"
      >
        <!-- TOP LIST-->
        @for (e of itemsTop; track e; let i = $index) {
          @if (isFeatureToggled(e)) {
          <li class="nav-list-item">
            <ng-container
              [ngTemplateOutlet]="e.route ? hasRoute : noRoute"
              [ngTemplateOutletContext]="{ e: e, testId: 'menu-item-' + e.route.replace('/', '') }"
            >
            </ng-container>
          </li>
          }
        }

        <!-- BOTTOM LIST -->
        <li class="bottom">
          @for (e of itemsBottom; track e) {
          <div>
            <ng-container [ngTemplateOutlet]="e.route ? hasRoute : noRoute" [ngTemplateOutletContext]="{ e: e }"> </ng-container>
          </div>
          }
        </li>
      </ul>
    </mat-drawer>
    <ng-content></ng-content>
  </mat-drawer-container>
</ng-container>

<ng-template #hasRoute let-e="e" let-testId="testId">
  <app-cde-sidenav-item
    [disabled]="isDisabled(e)"
    [permissionsPerRoute]="permissionsPerRoute"
    [hasRoute]="true"
    [item]="e"
    [attr.data-testid]="testId"
    [isExpanded]="isExpanded()"
    (clickedEvent)="handleClickedEvent($event)"
  >
  </app-cde-sidenav-item>
</ng-template>

<ng-template #noRoute let-e="e">
  <app-cde-sidenav-item [hasRoute]="false" [item]="e" [isExpanded]="isExpanded()"> </app-cde-sidenav-item>
</ng-template>
